<template>
    <div class="text-center">
        <template>
            <v-snackbar
                v-model="snackbar.show"
                :color="snackbar.color"
                :timeout=snackbarTimeout
                top
                right
                >
                <v-icon class="mb-1">{{`mdi-${snackbar.icon}`}}</v-icon>
                {{snackbar.text}}
            </v-snackbar>
        </template>
    </div>

</template>
<script>
    export default {
        props:{
            snackbar:{
                type: Object
            }
        },

        data: () => ({
            snackbarTimeout: 1500
        }),
        computed: {
        },
    }
</script>