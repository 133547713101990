<template>
    <v-container class="container-main">
        <tool-bar :toolbar="toolbar" :search="search" @insertDialog="openDialog()"></tool-bar>

        <v-data-table
            fixed-header
            dense
            :headers="emailHeader"
            :items="emailData"
            :search="search"
            :items-per-page="50" 
            :footer-props="footerProps"
            :height="tableHeight"
        >   
            <template v-slot:[`item.is_main_incharge`]="{ item }">
                <template v-if="!item.is_main_incharge">
                    <v-chip
                        class="ma-2"
                        color="#827717"
                        label
                        outlined
                        small
                        >
                        SUB IN-CHARGE
                    </v-chip>
                </template>
                <template v-else>
                    <v-chip
                        class="ma-2"
                        color="#004D40"
                        label
                        outlined
                        small
                        >
                        MAIN IN-CHARGE
                    </v-chip>
                </template>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small v-on="on" v-bind="attrs" icon @click="editEmailData(item)">
                            <v-icon class="edit-file-svg"></v-icon>
                        </v-btn>
                    </template>
                    <span>View Details</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small v-on="on" v-bind="attrs" icon @click="deleteEmail(item)">
                            <v-icon class="delete-file-svg"></v-icon>
                        </v-btn>
                    </template>
                    <span>Delete</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <v-row justify="center">
            <v-dialog
                v-model="editInchargeDialog"
                persistent
                width="550"
                @keydown.esc="editInchargeDialog = false"
            >
                <v-form
                    id="Edit"
                    ref="Edit"
                    @submit.prevent="Edit"
                    enctype="multipart/form-data"
                >
                    <v-card>
                        <v-card-title class="card-title">
                            <span class="title-headline"><b>{{ inchargeTitle }}</b></span>
                            <v-spacer></v-spacer>
                            <v-icon color="white" @click="editInchargeDialog = false">mdi-close</v-icon>
                        </v-card-title>
                        <v-card-text class="card-text">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-autocomplete
                                            v-model="tempOrderId"
                                            disabled
                                            label="Additional Ordering Industry"
                                            :items="industryData"
                                            :item-text="item => `( ${!item.additional_industry_id ? 'N/A' : item.additional_industry_id} )  ${item.product_industry_name}`"
                                            item-value="additional_industry_id"
                                            outlined
                                            required
                                            dense
                                            class="mb-2 required"
                                            :rules="rules.required"
                                            persistent-placeholder
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-autocomplete
                                            v-model="tempDepartment"
                                            label="Deparment"
                                            :items="departments"
                                            outlined
                                            required
                                            dense
                                            class="mb-2 required"
                                            :rules="rules.required"
                                            persistent-placeholder
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="6" style="margin-bottom: -18px !important;">
                                        <v-text-field
                                            v-model="tempUserId"
                                            label="User ID"
                                            outlined
                                            required
                                            dense
                                            class="mb-2 required"
                                            :rules="rules.required"
                                            persistent-placeholder
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="tempName"
                                            oninput="this.value = this.value.toUpperCase()"
                                            label="Name"
                                            outlined
                                            required
                                            dense
                                            class="mb-2 required"
                                            :rules="rules.required"
                                            persistent-placeholder
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" style="margin-bottom: -18px !important;">
                                        <v-text-field
                                            v-model="tempEmail"
                                            label="E-mail"
                                            outlined
                                            dense
                                            required
                                            class="mb-2 required"
                                            :rules="rules.required.concat(rules.email)"
                                            persistent-placeholder
                                        ></v-text-field>
                                    </v-col>
                                        <v-checkbox @click="recievedEmail()" v-model="tempEmailRecieved" class="mt-n6 ml-3" label="Recieved Email" hidden-details></v-checkbox>
                                </v-row>
                            </v-container>
                            <!-- // NOTE for dynamic supplier email -->
                            <v-container v-show="inchargeTitle == 'Edit Main In-charge'">
                                <v-divider></v-divider>
                                <v-row class="pt-8">
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="tempSupplierTo"
                                            label="Supplier Email To"
                                            outlined
                                            dense
                                            required
                                            class="mb-0"
                                            persistent-placeholder
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="tempSupplierCc"
                                            label="Supplier Email Cc"
                                            outlined
                                            dense
                                            required
                                            class="mb-2"
                                            persistent-placeholder
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn type="submit" :disabled="isSubmitted" class="mr-3 mb-2 mt-2" color="#0C2D48" style="color: white; height: 28px !important;" dense>{{ $t('global.submit') }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
                v-model="insertDialog"
                persistent
                width="650"
                @keydown.esc="insertDialog = false"
                scrollable
            >
                <v-form
                    id="Insert"
                    ref="Insert"
                    @submit.prevent="Insert"
                    enctype="multipart/form-data"
                >
                    <v-card>
                        <v-card-title class="card-title">
                            <span class="title-headline">{{ $t('global.addNewEmail') }}</span>
                            <v-spacer></v-spacer>
                            <v-icon color="white" @click="insertDialog = false">mdi-close</v-icon>
                        </v-card-title>
                        <v-card-text class="card-text">
                            <v-container>
                                <v-row>
                                    <v-col cols="12" style="margin-bottom: -23px;">
                                        <v-autocomplete
                                            v-model="add.additional_order_industry"
                                            :label="$t('industryHeader.additionalOrderingIndustry')"
                                            :items="filteredIndustryData"
                                            :item-text="item => `( ${!item.additional_industry_id ? 'N/A' : item.additional_industry_id} )  ${item.product_industry_name}`"
                                            item-value="additional_industry_id"
                                            outlined
                                            dense
                                            class="mb-2 required"
                                            :rules="rules.required"
                                            required
                                            persistent-placeholder
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-card class="mb-3">
                                            <v-card-title class="form-card-title">
                                                <v-icon dark size="20" class="mr-2">mdi-card-bulleted-outline</v-icon>
                                                <span class="span-style"><b>{{ $t('global.mainIncharge') }}</b></span>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-row class="mt-4">
                                                    <v-col cols="6">
                                                        <v-autocomplete
                                                            v-model="add.main_department"
                                                            :label="$t('global.department')"
                                                            :items="departments"
                                                            outlined
                                                            required
                                                            dense
                                                            class="mb-2 required"
                                                            :rules="rules.required"
                                                            persistent-placeholder
                                                        ></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-text-field
                                                            v-model="add.main_name"
                                                            oninput="this.value = this.value.toUpperCase()"
                                                            :label="$t('global.name')"
                                                            outlined
                                                            required
                                                            dense
                                                            class="mb-2 required"
                                                            :rules="rules.required"
                                                            persistent-placeholder
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="6" style="margin-bottom: -18px !important;">
                                                        <v-text-field
                                                            v-model="add.main_user_id"
                                                            type="number"
                                                            :label="$t('global.userId')"
                                                            onKeyPress="if(this.value.length==6)return false;"
                                                            dense
                                                            outlined
                                                            persistent-placeholder
                                                            required
                                                            autocomplete="off"
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="6" style="margin-bottom: -18px !important;">
                                                        <v-text-field
                                                            v-model="add.main_email"
                                                            :label="$t('global.email')"
                                                            outlined
                                                            dense
                                                            required
                                                            class="mb-2 required"
                                                            :rules="rules.required.concat(rules.email)"
                                                            persistent-placeholder
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-checkbox v-model="withSubIncharge" dense style="float: right; margin-bottom: -10px;" :label="$t('global.withSubIncharge')"></v-checkbox>    
                                            </v-card-actions>
                                            
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <template v-if="withSubIncharge">
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-card class="mb-3">
                                                <v-card-title class="form-card-title">
                                                    <v-icon dark size="20" class="mr-2">mdi-card-bulleted-outline</v-icon>
                                                    <span class="span-style"><b>{{ $t('global.subIncharge') }}</b></span>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-row class="mt-4">
                                                    <v-col cols="6">
                                                        <v-autocomplete
                                                            v-model="add.sub_department"
                                                            :label="$t('global.department')"
                                                            :items="departments"
                                                            outlined
                                                            required
                                                            dense
                                                            class="mb-2 required"
                                                            :rules="rules.required"
                                                            persistent-placeholder
                                                        ></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-text-field
                                                            v-model="add.sub_name"
                                                            oninput="this.value = this.value.toUpperCase()"
                                                            :label="$t('global.name')"
                                                            outlined
                                                            required
                                                            dense
                                                            class="mb-2 required"
                                                            :rules="rules.required"
                                                            persistent-placeholder
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="6" style="margin-bottom: -18px !important;">
                                                        <v-text-field
                                                            v-model="add.sub_user_id"
                                                            type="number"
                                                            :label="$t('global.userId')"
                                                            onKeyPress="if(this.value.length==6)return false;"
                                                            dense
                                                            outlined
                                                            persistent-placeholder
                                                            class="mb-2 required"
                                                            :rules="rules.required"
                                                            required
                                                            autocomplete="off"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="6" style="margin-bottom: -18px !important;">
                                                        <v-text-field
                                                            v-model="add.sub_email"
                                                            :label="$t('global.email')"
                                                            outlined
                                                            dense
                                                            required
                                                            class="mb-2 required"
                                                            :rules="rules.required.concat(rules.email)"
                                                            persistent-placeholder
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn type="submit" :disabled="isSubmitted" class="mr-3 mb-2 mt-2" color="#0C2D48" style="color: white; height: 28px !important;" dense>{{ $t('global.submit') }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>

            </v-dialog>
        </v-row>

        <float-action
            :floatbtn="floatbtn" 
            :insertDialog="insertDialog"
            @toggleInsertDialog="toggleInsertDialog($event)"
        ></float-action>


        <snack-bar :snackbar="snackbar"></snack-bar>
    </v-container>
</template>
<script>
    import axios from 'axios';
    import swal from 'sweetalert2';
    import { mapActions, mapState } from 'vuex';
    import Toolbar from '../includes/Toolbar.vue'
    import FloatAction from '../includes/FloatAction.vue'
    import SnackBar from './SnackBar.vue'

    export default {
        components: {
            "tool-bar": Toolbar,
            "float-action": FloatAction,
            "snack-bar": SnackBar
        },
        
        data: () => ({
            floatbtn: {
                add: false,
            },
            toolbar: {
                title: 'toolbar.homeTitle',
                sub_title: 'navigation.emailMaster',
                hasSearch: true,
                insertDialog: true,
            },
            snackbar: {
                color: "blue-grey",
                text: "",
                show: false,
            },
            insertDialog: false,
            add: {},
            edit: {},
            editInchargeDialog: false,
            inchargeTitle: '',
            tableHeight: window.innerHeight - 210,
            // emailData: [],
            selectAll: false,
            isSubmitted: false,
            withSubIncharge: false,
            departments: [
                'FRAME',
                'HACCHU',
                'SUIDOU',
                'UNIT WIRING',
                'DENKI',
            ],
            tempOrderId: '',
            tempDepartment: '',
            tempName: '',
            tempUserId: '',
            tempEmail: '',
            tempId: 0,
            tempEmailRecieved:false,
            tempRecieved: 0,
            // additionalIndustryData: [],
            tempSupplierTo:'', // NOTE for dynamic supplier email
            tempSupplierCc:'', // NOTE for dynamic supplier email
        }),
        computed: {
            ...mapState(['search', 'accessRightData', 'rules', 'emailData', 'industryData']),
            filteredIndustryData() {
                const isExisted = Array.from(new Set(this.emailData.map(res => res.additional_industry_id)));
                
                const filteredData = this.industryData.map(m => ({
                    additional_industry_id: m.additional_industry_id,
                    created_at: m.created_at,
                    deleted_at: m.deleted_at,
                    id: m.id,
                    product_industry_name: m.product_industry_name,
                    updated_at: m.updated_at,
                    disabled: isExisted.includes(m.additional_industry_id),
                }));

                return filteredData;
            },
            footerProps() {
                const len = this.emailData.length / 50;
                let arr = [];
                let allData = [-1];
                var foorterNum = 0;
                if(len % 0 != 0) {
                    foorterNum = Math.trunc(len) + 1;
                }
                for(let i = 1; i <= foorterNum; i++) {
                    arr.push(i * 50)
                }
                if(arr.length == 1) {
                    arr.push(100)
                } 

                return {'items-per-page-options': arr.concat(allData)};
            },
            emailHeader() {
                let header = [
                    {
                        text: this.$t('global.id'),
                        align: 'center',
                        value: 'additional_industry_id',
                        class: 'white--text title'
                    },
                    {
                        text: this.$t('industryHeader.additionalOrderingIndustry'),
                        align: 'center',
                        value: 'product_industry_name',
                        class: 'white--text title'
                    },
                    {
                        text: this.$t('global.department'),
                        align: 'center',
                        value: 'department',
                        class: 'white--text title'
                    },
                    {
                        text: this.$t('global.userId'),
                        align: 'center',
                        value: 'user_id',
                        class: 'white--text title'
                    },
                    {
                        text: this.$t('global.name'),
                        align: 'center',
                        value: 'user_name',
                        class: 'white--text title'
                    },
                    {
                        text: this.$t('global.email'),
                        align: 'center',
                        value: 'email',
                        class: 'white--text title'
                    },
                    {
                        text: this.$t('global.incharge'),
                        align: 'center',
                        value: 'is_main_incharge',
                        class: 'white--text title'
                    },
                    {
                        text: this.$t('global.action'),
                        align: 'center',
                        value: 'actions',
                        class: 'white--text title'
                    }
                ]
                
                if(!this.getAccessSettings()) {
                    header.splice(-1);
                } 

                return header;
            },
        },
        methods: {
            ...mapActions(['getEmail', 'loadCategoryData']),
            cntRowIndustry(array, industryId) {
                let cnt = 0;
                array.forEach(res => {
                    if(res.additional_industry_id == industryId) {
                        cnt++;
                    }
                })

                return cnt;
            },
            toggleInsertDialog(val) {
                this.insertDialog = val;
            },
            async Insert(){
                this.isSubmitted = true;
                if(this.$refs.Insert.validate()) {

                    this.add['created_by'] = this.loggedInUser.user_id;

                    swal
                        .fire({
                            title: "Confirmation",
                            text: `Are you sure to add this record?`,
                            showDenyButton: true,
                            showCancelButton: false,
                            confirmButtonText: `Confirm`,
                            denyButtonText: `Cancel`,
                    }).then(result => {
                        if(result.isConfirmed) {
                            axios({
                                method: 'post',
                                url: `${window.api}/mysql/insertEmail`,
                                data: this.add,
                                headers: {
                                    'x-api-key' : process.env.VUE_APP_HEADERS
                                }
                            }).then(res => {
                                if(res.data) {

                                    swal.fire({
                                        position: "center",
                                        icon: "success",
                                        title: `Successfully added data`,
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });
                                    this.insertDialog = false;
                                    this.getEmail();

                                    this.$socket.emit('gcTsuikaData', {
                                        system: 'gc-tsuika',
                                        request: 'updateEmailMaster',
                                        dispatchType: 'request-local',
                                        port: this.windowLoc.port,
                                        protocol: this.windowLoc.protocol,
                                        hostname: this.windowLoc.hostname,
                                    })
                                }
                            })
                        }
                    })
                }
            },

            Edit() {
                if(this.$refs.Edit.validate()) {
                    swal
                    .fire({
                        text: "Are you sure you want to edit this data?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes",
                    })
                    .then((result) => {
                    if (result.isConfirmed) {
                        const obj = {
                            id: this.tempId,
                            additional_order_industry: this.tempOrderId,
                            user_id: this.tempUserId,
                            department: this.tempDepartment,
                            user_name: this.tempName,
                            email: this.tempEmail,
                            is_email_reciever : this.tempRecieved,
                            // NOTE for dynamic supplier email
                            email_to_supplier : this.tempSupplierTo,
                            email_cc_supplier : this.tempSupplierCc,
                        }
                        axios({
                            method: 'post',
                            url: `${window.api}/mysql/updateEmail/update`,
                            data: obj,
                            headers: {
                                'x-api-key' : process.env.VUE_APP_HEADERS
                            }
                        }).then(() => {
                            this.editInchargeDialog = false;
                            swal.fire({
                                position: "center",
                                icon: "success",
                                title: "You have successfully edit data",
                                showConfirmButton: false,
                                timer: 1500,
                            });

                            this.getEmail();

                            this.$socket.emit('gcTsuikaData', {
                                system: 'gc-tsuika',
                                request: 'updateEmailMaster',
                                dispatchType: 'request-local',
                                port: this.windowLoc.port,
                                protocol: this.windowLoc.protocol,
                                hostname: this.windowLoc.hostname,
                            })
                        })
                        
                    }
                });


                    
                }
            },

            recievedEmail(){
                if (this.tempEmailRecieved === true){
                    this.tempRecieved = 1
                }else{
                    this.tempRecieved = 0
                }
            },
            openDialog() {
                this.insertDialog = true;
            },
            editEmailData(item){
                // console.log(item);
                this.tempId = item.id;
                this.tempOrderId = item.additional_industry_id;
                this.tempDepartment = item.department;
                this.tempUserId = item.user_id;
                this.tempName = item.user_name;
                this.tempEmail = item.email;
                this.tempEmailRecieved = item.is_email_reciever;
                // NOTE for dynamic supplier email
                this.tempSupplierTo = item.email_to_supplier;
                this.tempSupplierCc = item.email_cc_supplier;

                this.editInchargeDialog = true;

                if(item.is_main_incharge) {
                    this.inchargeTitle = 'Edit Main In-charge'
                } else {
                    this.inchargeTitle = 'Edit Sub In-charge'
                }
            },
            deleteEmail(item) {
                if(item) {
                    swal
                        .fire({
                            title: "Confirmation",
                            text: `Are you sure to delete this record?`,
                            showDenyButton: true,
                            showCancelButton: false,
                            confirmButtonText: `Confirm`,
                            denyButtonText: `Cancel`,
                        })
                        .then( async (result) => {
                        if (result.isConfirmed) {

                            await axios({
                                method: 'POST',
                                url: `${window.api}/mysql/deletEmail/delete`,
                                data: item,
                                headers: {
                                    'x-api-key' : process.env.VUE_APP_HEADERS
                                }
                            }).then(() => {
                                swal.fire({
                                    position: "center",
                                    icon: "success",
                                    title: "Your data has beed deleted",
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                                this.getEmail()

                                this.$socket.emit('gcTsuikaData', {
                                    system: 'gc-tsuika',
                                    request: 'updateEmailMaster',
                                    dispatchType: 'request-local',
                                    port: this.windowLoc.port,
                                    protocol: this.windowLoc.protocol,
                                    hostname: this.windowLoc.hostname,
                                })
                            })
                        }
                    });
                }
            }
        },
        watch: {
            insertDialog(val) {
                if( !val ) {
                    this.$refs.Insert.reset();
                    Object.keys(this.add).forEach(myProp => {
                        delete this.add[myProp];
                    })

                    this.isSubmitted = false;

                }
            },
            withSubIncharge(val) {
                if(!val) {
                    delete this.add.sub_department;
                    delete this.add.sub_name;
                    delete this.add.sub_user_id;
                    delete this.add.sub_email;
                }
            },
            editInchargeDialog(val){
                if(!val) {
                    this.$refs.Edit.reset();
                    this.isSubmitted = false;
                }
            },
            
        },
        mounted() {
        },
        created() {
            this.$store.state.editMode = false;
            this.$store.state.search = '';

            this.getEmail().then(() => {
                this.loadCategoryData();
            })

        }
    }

</script>
<style scoped>
.v-data-table ::v-deep th {
    font-size: 13px !important;
    background-color: #274761 !important;
    font-family: 'Arial' !important;
    text-align: center !important;
    border: 1px solid white !important;
}
</style>